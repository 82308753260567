import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
  //{ path: 'news', loadChildren: './pages/news/news.module#NewsPageModule' },
  { path: 'feed', loadChildren: './pages/news/news.module#NewsPageModule' },
  { path: 'network', loadChildren: './pages/network/network.module#NetworkPageModule' },
  { path: 'jobs', loadChildren: './pages/_jobs/jobs/jobs.module#JobsPageModule' },
  { path: 'pilot-details', loadChildren: './pages/_pilot/pilot-details/pilot-details.module#PilotDetailsPageModule' },
  { path: 'edit-profile', loadChildren: './pages/_profile/edit-profile/edit-profile.module#EditProfilePageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'changephoto', loadChildren: './pages/changephoto/changephoto.module#ChangephotoPageModule' },
  { path: 'job-details/:id', loadChildren: './pages/_jobs/job-details/job-details.module#JobDetailsPageModule' },
  { path: 'add-job', loadChildren: './pages/_jobs/add-job/add-job.module#AddJobPageModule' },
  { path: 'add-logo', loadChildren: './pages/_jobs/add-logo/add-logo.module#AddLogoPageModule' },
  { path: 'license', loadChildren: './pages/_pilot/license/license.module#LicensePageModule' },
  { path: 'total-flight-hours', loadChildren: './pages/_pilot/total-flight-hours/total-flight-hours.module#TotalFlightHoursPageModule' },
  { path: 'type-ratings', loadChildren: './pages/_pilot/type-ratings/type-ratings.module#TypeRatingsPageModule' },
  { path: 'ratings', loadChildren: './pages/_pilot/ratings/ratings.module#RatingsPageModule' },
  { path: 'instructor-ratings', loadChildren: './pages/_pilot/instructor-ratings/instructor-ratings.module#InstructorRatingsPageModule' },
  { path: 'certified', loadChildren: './pages/_pilot/certified/certified.module#CertifiedPageModule' },
  { path: 'qualifications', loadChildren: './pages/_pilot/qualifications/qualifications.module#QualificationsPageModule' },
// tslint:disable-next-line: max-line-length
  { path: 'radio-license-language-level', loadChildren: './pages/_pilot/radio-license-language-level/radio-license-language-level.module#RadioLicenseLanguageLevelPageModule' },
  { path: 'medical', loadChildren: './pages/_pilot/medical/medical.module#MedicalPageModule' },
  { path: 'anonymousp-profile', loadChildren: './pages/_pilot/anonymous-profile/anonymousp.module#AnonymousPageModule' },

  { path: 'previous-positions', loadChildren: './pages/_pilot/previous-positions/previous-positions.module#PreviousPositionsPageModule' },
  { path: 'availability', loadChildren: './pages/_pilot/availability/availability.module#AvailabilityPageModule' },
  { path: 'name-logo', loadChildren: './pages/_jobs/name-logo/name-logo.module#NameLogoPageModule' },
  { path: 'search-pilot', loadChildren: './pages/_pilot/search-pilot/search-pilot.module#SearchPilotPageModule' },
  { path: 'company-details/:id', loadChildren: './pages/_company/company-details/company-details.module#CompanyDetailsPageModule' },
// tslint:disable-next-line: max-line-length
  { path: 'pilot-registration', loadChildren: './pages/_registration/pilot-registration/pilot-registration.module#PilotRegistrationPageModule' },
  { path: 'pilot-registration/:usrid/:id', loadChildren: './pages/_registration/pilot-registration/pilot-registration.module#PilotRegistrationPageModule' },
// tslint:disable-next-line: max-line-length
  { path: 'company-registration', loadChildren: './pages/_registration/company-registration/company-registration.module#CompanyRegistrationPageModule' },
  { path: 'update-job/:id', loadChildren: './pages/_jobs/update-job/update-job.module#UpdateJobPageModule' },
  { path: 'add-news', loadChildren: './pages/_news/add-news/add-news.module#AddNewsPageModule' },
  { path: 'news-details/:id', loadChildren: './pages/_news/news-details/news-details.module#NewsDetailsPageModule' },
  { path: 'update-news/:id', loadChildren: './pages/_news/update-news/update-news.module#UpdateNewsPageModule' },
  { path: 'apply-job', loadChildren: './pages/_jobs/apply-job/apply-job.module#ApplyJobPageModule' },
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'changelogo', loadChildren: './pages/changelogo/changelogo.module#ChangelogoPageModule' },
  { path: 'change-background', loadChildren: './pages/photos/change-background/change-background.module#ChangeBackgroundPageModule' },

  { path: 'invite', loadChildren: './pages/_pilot/invite/invite.module#InvitePageModule' },
  { path: 'subscriptions', loadChildren: './pages/_profile/subscriptions/subscriptions.module#SubscriptionsPageModule' },
  { path: 'company-invite', loadChildren: './pages/_company/company-invite/company-invite.module#CompanyInvitePageModule' },
  { path: 'company-subscription', loadChildren: './pages/_company/company-subscription/company-subscription.module#CompanySubscriptionPageModule' },
  { path: 'company-subscrip-bulkjob', loadChildren: './pages/_company/company-subscrip-bulkjob/company-subscrip-bulkjob.module#CompanySubscripBulkjobPageModule' },
  { path: 'language-popover', loadChildren: './pages/language-popover/language-popover.module#LanguagePopoverPageModule' },
  { path: 'type-ratings-modal', loadChildren: './pages/_pilot/type-ratings/type-ratings-modal/type-ratings-modal.module#TypeRatingsModalPageModule' },
  { path: 'feed-create', loadChildren: './pages/feed/feed-create/feed-create.module#FeedCreatePageModule' },
  { path: 'feed-comment', loadChildren: './pages/feed/feed-comment/feed-comment.module#FeedCommentPageModule' },
  { path: 'feed-details', loadChildren: './pages/feed/feed-details/feed-details.module#FeedDetailsPageModule' },
  { path: 'feed-details/:id', loadChildren: './pages/feed/feed-details/feed-details.module#FeedDetailsPageModule' }

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
