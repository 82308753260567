<ion-header>
  <ion-toolbar>
    <ion-title>{{'NETWORK.title' | translate}}</ion-title>
    <ion-buttons slot="start">
        <!-- <ion-button (click)="openLanguagePopover($event)">
            <ion-icon name="globe" slot="icon-only"></ion-icon>
          </ion-button> -->
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-menu-button>
        <ion-icon name="menu"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="view != 'filters'">
    <div class="searchTools">
      <ion-grid>
        <ion-row>
          <ion-col size="11">
            <ion-searchbar
              (ionInput)="onChangeTime()"
              (ionChange)="filterBySearch()"
              [(ngModel)]="serach"              
            ></ion-searchbar>
          </ion-col>
          <ion-col *ngIf="view == 'pilot'">
            <ion-icon
              (click)="filters()"
              name="options"
              color="medium"
              class="filterBtn"
              padding-vertical
            ></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-segment padding>
        <ion-segment-button checked (click)="pilotView()" value="Pilots">
          {{'NETWORK.pilots' | translate}}
        </ion-segment-button>
        <ion-segment-button (click)="companyView()" value="Companies">
          {{'NETWORK.companies' | translate}}
        </ion-segment-button>
      </ion-segment>
    </div>

    <!-- <ion-item *ngIf="view=='pilot'" detail="true">
          <ion-label (click)="filters()">Filters ({{filter}})</ion-label>
        </ion-item> -->

    <ion-list>
      <div *ngIf="view == 'pilot'">
        <ion-list-header>
          <ion-label
            >{{'NETWORK.found' | translate}} <b>{{ count }}</b></ion-label
          >
        </ion-list-header>

        <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
          <ion-infinite-scroll-content
            class="minheight0"
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>

        <div *ngFor="let c of pilotSearch; let i = index">
          <div *ngIf="c.anonymous_looking; then anonymous; else real">
            {{'NETWORK.set_login' | translate}}
          </div>
          <!-- <div *ngIf="c.anonymous_looking ; else anonymous">
        <ion-item>
            <span>
                This pilot has chosen to be anonymous. If you would like to contact the pilot, please contact AirPilotBase at email: info@airpilotbase.com or phone: +45 60550728
            </span>
        </ion-item>
    </div> -->
          <ng-template #anonymous>
            <ion-item
              (click)="onGotoPilotDetails(c)"
              *ngIf="c.fixed_wing_hour + c.helicopter_hour > 0"
            >
              <ion-avatar slot="start">
                <img src="{{ photo }}" />
              </ion-avatar>
              <ion-label>
                <h2>{{'NETWORK.anonymous_pilot' | translate}}</h2>
                <p>
                  <span
                    *ngIf="c.fixed_wing || c.helicopter"
                    style="margin-right:10px;"
                    >{{ c.fixed_wing_hour + c.helicopter_hour }}</span
                  >
                  <ion-icon
                    *ngIf="c.fixed_wing"
                    style="margin-right:10px;"
                    src="/assets/icon/total-flight-hours_fly.svg"
                  ></ion-icon>
                  <ion-icon
                    *ngIf="c.helicopter"
                    style="margin-right:10px;"
                    src="/assets/icon/total-flight-hours_helicopter.svg"
                  ></ion-icon>
                  <span *ngIf="c.atplA" style="margin-right:10px;"
                    >ATPL(A)</span
                  >
                  <span *ngIf="c.atplH" style="margin-right:10px;"
                    >ATPL(H)</span
                  >
                  <span *ngIf="c.cplA" style="margin-right:10px;">CPL(A)</span>
                  <span *ngIf="c.cplH" style="margin-right:10px;">CPL(H)</span>
                </p>
              </ion-label>
              <!--<div *ngFor="let sub of subscripUser" style="align-content: center;">
                 

                  <ion-icon *ngIf="c.id == sub.id" name="star"></ion-icon>

              </div>-->
              <ion-icon color="medium" *ngIf="getSubscriptionData(c.id)" size="small" name="star"></ion-icon>

            </ion-item>
          </ng-template>
          <ng-template #real>
            <ion-item
              *ngIf="c.fixed_wing_hour + c.helicopter_hour > 0"
              (click)="onGotoPilotDetails(c)"
            >
              <ion-avatar slot="start">
                <img
                  *ngIf="c?.photoURL; else blankimag"
                  src="{{ c?.photoURL }}"
                />
              </ion-avatar>

              <ng-template #blankimag>
                <img src="{{ photo }}" />
              </ng-template>
              <ion-label>
                <h2>
                  {{ c?.name }}
                  <!-- <ion-badge *ngIf="c?.id!=ownProfile?.uid" color="pilot">Active</ion-badge> -->
                </h2>
                <p>
                  <span
                    *ngIf="c.fixed_wing || c.helicopter"
                    style="margin-right:10px;"
                    >{{ c.fixed_wing_hour + c.helicopter_hour }}</span
                  >
                  <ion-icon
                    *ngIf="c.fixed_wing"
                    style="margin-right:10px;"
                    src="/assets/icon/total-flight-hours_fly.svg"
                  ></ion-icon>
                  <ion-icon
                    *ngIf="c.helicopter"
                    style="margin-right:10px;"
                    src="/assets/icon/total-flight-hours_helicopter.svg"
                  ></ion-icon>
                  <span *ngIf="c.atplA" style="margin-right:10px;"
                    >ATPL(A)</span
                  >
                  <span *ngIf="c.atplH" style="margin-right:10px;"
                    >ATPL(H)</span
                  >
                  <span *ngIf="c.cplA" style="margin-right:10px;">CPL(A)</span>
                  <span *ngIf="c.cplH" style="margin-right:10px;">CPL(H)</span>
                </p>
              </ion-label>

             
              <!--<div *ngFor="let sub of subscripUser" style="align-content: center;"> 
                <ion-icon color="medium" *ngIf="c.id == sub.id" size="small" name="star"></ion-icon>
              </div>-->
              <ion-icon color="medium" *ngIf="getSubscriptionData(c.id)" size="small" name="star"></ion-icon>
            </ion-item>
          </ng-template>
        </div>
      </div>
      <div *ngIf="view == 'company'">
        <ion-list-header>
          <ion-label
            >{{'NETWORK.found' | translate}} <b>{{ countCompanies }}</b></ion-label
          >
        </ion-list-header>

        
        <ion-infinite-scroll threshold="100px" (ionInfinite)="loadUserData($event)">
            <ion-infinite-scroll-content
              class="minheight0"
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>

        <ion-item
          *ngFor="let c of companySearch"
          (click)="onGotoPilotDetails(c)"
        >
          <ion-avatar slot="start">
            <img *ngIf="c?.logo; else blankimag" src="{{ c?.logo }}" />
          </ion-avatar>
          <ng-template #blankimag>
            <ion-icon style="font-size: 40px" name="business"></ion-icon>
          </ng-template>
          <ion-label>
            <h2>{{ c.Name }}</h2>
            <p>{{ c.City }} {{ c.Country }}</p>
          </ion-label>
          
        </ion-item>
        <br />
        <br />
      </div>
    </ion-list>
  </div>
  <div *ngIf="view == 'filters'" padding>
    <ion-item [disabled]="itemFixedWingDisabled">
      <ion-label>{{'NETWORK.min_hours' | translate}} <i>({{'NETWORK.fixed_wing' | translate}})</i></ion-label>
      <ion-select
        (ionChange)="changeFixedWingHours($event.detail)"
        [interfaceOptions]="customAlertHourOptions"
        interface="alert"
        placeholder="Select"
      >
        <ion-select-option *ngFor="let item of hours" [value]="item.value">{{
          item.name
        }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item [disabled]="itemHelicopterHoursDisabled">
      <ion-label>{{'NETWORK.min_hours' | translate}} <i>({{'NETWORK.helicopter' | translate}})</i></ion-label>
      <ion-select
        (ionChange)="changeHelicopterHours($event.detail)"
        [interfaceOptions]="customAlertHourOptions"
        interface="alert"
        placeholder="Select"
      >
        <ion-select-option *ngFor="let item of hours" [value]="item.value">{{
          item.name
        }}</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-label>{{'NETWORK.license' | translate}}</ion-label>
      <ion-select
        (ionChange)="changeLicense($event.detail)"
        [interfaceOptions]="customAlertOptions"
        interface="alert"
        placeholder="Select"
      >
        <ion-select-option *ngFor="let item of license" [value]="item.value">{{
          item.name
        }}</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
        <ion-label>{{'NETWORK.student_pilot' | translate}} </ion-label>
        <ion-select
          (ionChange)="changeStudent($event.detail)"
          [interfaceOptions]="customAlertOptions"
          interface="alert"
          placeholder="Select"
        >
          <ion-select-option *ngFor="let item of studentPilot" [value]="item.value">{{
            item.name
          }}</ion-select-option>
        </ion-select>
      </ion-item>

    <ion-grid>


      <ion-row>
        <ion-col>
          <ion-button expand="block" (click)="filterSearch()">{{'NETWORK.filter' | translate}} </ion-button>
        </ion-col>
        <ion-col>
          <ion-button expand="block" (click)="cancelFilters()" color="light">
            Reset
          </ion-button>
        </ion-col>
        </ion-row>
      </ion-grid>

  </div>
</ion-content>
