import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selected = '';

  constructor(private tanslate: TranslateService, private storage: Storage) { }

  setInitialAppLanguage() {
    const language = this.tanslate.getBrowserLang();
    this.tanslate.setDefaultLang(language);
    this.storage.get(language).then(val => {
      if (val) {
        this.setLanugage(val);
        this.selected = val;
      }
    });

    if (navigator.language.includes('en')) {
      console.log(navigator.language);
      this.setLanugage('en');
    } else {
      this.setLanugage('en');
    }
  }

  getLanguages() {
    return [
      { text: 'English', value: 'en', img: 'assests/imgs/en.png' },
      { text: 'Danish', value: 'da', img: 'assests/img/da.png'}
    ];
  }

  setLanugage(lng = 'da') {
    this.tanslate.use(lng);
    this.selected = lng;
    this.storage.set(LNG_KEY, lng);
  }
}
