import { Injectable } from '@angular/core';
import { Subscription } from '../../models/subscription.model';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { AlertController } from '@ionic/angular';


 

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  private subscriptionCollection: AngularFirestoreCollection<Subscription>;
  private subscriptionHistoryCollection: AngularFirestoreCollection<Subscription>;


  private subscription: Observable<Subscription[]>;
  private subscriptionHistory: Observable<Subscription[]>;

  CONTECT_URL:any = "https://airpilotbase.com/about/contact/?utm_source=app&utm_medium=banner";


  constructor(
    public db: AngularFirestore, 
    public alertController: AlertController,
    private router: Router) {

    this.subscriptionCollection = db.collection<Subscription>('subscription');
    this.subscriptionHistoryCollection = db.collection<Subscription>('subscription_history');


    this.subscription = this.subscriptionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        })
      })
    );

    this.subscriptionHistory = this.subscriptionHistoryCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        })
      })
    );
  }



  getSubscription() {
    this.subscriptionCollection = this.db.collection<Subscription>('subscription');

    this.subscription = this.subscriptionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        })
      })
    );
    return this.subscription;
  }

  getSubscriptionHistory(){
    this.subscriptionHistoryCollection = this.db.collection<Subscription>('subscription');

    this.subscriptionHistory = this.subscriptionHistoryCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        })
      })
    );
    return this.subscriptionHistory;
  }



  getUserSubscription(userid) {
    return this.subscriptionCollection.doc(userid).valueChanges();
  }

  getSubscriptionUserHistory(userid){
    return this.subscriptionHistoryCollection.doc(userid).valueChanges();
  }




  addSubscription(userId: any, subscrip: any) {
    var demo; 
    demo = {[userId]: subscrip};
    return this.subscriptionCollection.doc(userId).set(demo);
  }

  updateSubscription(usrId, subscrip: any){
    // debugger;
    var demo;

    demo = {[usrId]: subscrip};
    return this.subscriptionCollection.doc(usrId).update(demo);
  }

  updateInvited(usrId, subscrip: any){
    return this.subscriptionCollection.doc(usrId).update(subscrip);
  }

  removeSubscription(usrId){
    return this.subscriptionCollection.doc(usrId).delete();
  }




  addSubscriptionHistory(usrId, subscrip: any){
    let demodata;
    let refid = this.db.createId();
    demodata = { [refid]: subscrip };
    return this.subscriptionHistoryCollection.doc(usrId).set(demodata);
  }
  updateSubscriptionHistory(usrId, subscrip: any){
    var demo;
    let refid = this.db.createId();
    demo = {[refid]: subscrip};
    return this.subscriptionHistoryCollection.doc(usrId).update(demo);
  }

  subscriptionUser: any = [];
  subscriberPlanUser: Boolean = false;
  getAllSubcription(subsUser) {
    this.subscriptionUser = subsUser;
    const user = sessionStorage.getItem('user');
    
    if (user != null) {
      
      const userId = JSON.parse(user).uid;
      if (userId != null) {
        if (this.subscriptionUser.length != 0 && this.subscriptionUser != null) {
          const mykey: any = [];
          if (this.subscriptionUser != null && this.subscriptionUser.length != 0) {
            for (const key in this.subscriptionUser) {
              if (key != 'id') {
                if (key == userId) {
                  mykey.push(key);
                }
              }
            }


            if (mykey[0] == userId) {
              const apply_date = this.subscriptionUser[mykey].apply_date;
              const expiry_date = this.subscriptionUser[mykey].expiry_date;

              if (apply_date != null && expiry_date != null) {
                const cDate = apply_date.seconds * 1000;

                const apply_converted_date = moment(new Date(cDate)).format('YYYY,MM,DD hh:mm:ss');
                // console.log("apply_converted_date===" + apply_converted_date);

                let expiry_con_date = expiry_date.toLocaleString();
                expiry_con_date = expiry_con_date.replace('at ', '');
                const expiry_converted_date = moment(new Date(expiry_con_date)).format('YYYY,MM,DD hh:mm:ss');
                // console.log("ddd===" + expiry_converted_date);

                if (expiry_converted_date != 'Invalid date' && expiry_converted_date != null) {
                  if (apply_converted_date > expiry_converted_date) {
                    this.subscriberPlanUser = false;
                    // this.presentPlanExpiryAlertPrompt("This feature requires subscription.<br> Please <a href='/subscriptions'>click here to change your plan</a>.");
                    this.presentPlanExpiryAlertPrompt('This feature is reserved for subscribers. Please <a href=\'/subscriptions\'>click here to upgrade</a>.');
                  } else if (apply_converted_date == expiry_converted_date) {
                    this.subscriberPlanUser = false;
                    // this.presentPlanExpiryAlertPrompt("This feature requires subscription.<br> Please <a href='/subscriptions'>click here to change your plan</a>.");
                    this.presentPlanExpiryAlertPrompt('This feature is reserved for subscribers. Please <a href=\'/subscriptions\'>click here to upgrade</a>.');
                  } else { (apply_converted_date < expiry_converted_date); }
                  {
                    this.subscriberPlanUser = true;
                    return;
                  }
                }
              }
            }

          }
        } else {
          this.subscriberPlanUser = false;
          // this.presentPlanExpiryAlertPrompt("This feature requires requires subscription.<br> Please <a href='/subscriptions'>click here to change your plan</a>.");
          this.presentPlanExpiryAlertPrompt('This feature is reserved for subscribers. Please <a href=\'/subscriptions\'>click here to upgrade</a>.');
        }
      }
      
  }
  else{
    
    this.router.navigate(['/login']);
  }
  }
  async presentPlanExpiryAlertPrompt(msg) {
    const alert = await this.alertController.create({
      header: 'Subscription required',
      message: msg,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        /*handler: () => {
          }*/
        }, {
          text: 'Ok',
          /*handler: (data) => {
          }*/
        }
      ]
    });
    await alert.present();
  }
}
