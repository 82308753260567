import { Component, OnInit, ViewChild } from '@angular/core';
import { PilotService } from '../../services/pilot/pilot.service';
import {JobService } from '../../services/job/job.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { SubscriptionsService } from '../../services/subscriptions/subscriptions.service';
import * as firebase from 'firebase';
import {  NavController } from '@ionic/angular';
import { IonInfiniteScroll } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { LanguagePopoverPage } from '../../pages/language-popover/language-popover.page';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-network',
  templateUrl: './network.page.html',
  styleUrls: ['./network.page.scss'],
})
export class NetworkPage implements OnInit {

  subscriptionUser: any = [];
  subscriberPlanUser: Boolean = false;

  loggedInOrNot = false;

  constructor(
    private pilotService: PilotService,
    private router: Router,
    private jobService: JobService,
    public alertController: AlertController,
    private loginService: LoginService,
    private subscriptionsService: SubscriptionsService,
    public loadingController: LoadingController,
    private navCtrl: NavController,
    private angularFireAuth: AngularFireAuth,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private translate: TranslateService
    ) {
      this.ownProfile = JSON.parse(sessionStorage.getItem('user'));
      // console.log("this");
      // this.companySearch = this.companies;

      // subscriptions
      // tslint:disable-next-line: no-unused-expression
      this.count = 0;

      if (JSON.parse(sessionStorage.getItem('user')) != null) {
        this.loggedInOrNot = true;
      } else {
        this.loggedInOrNot = false;
      }

      if (JSON.parse(sessionStorage.getItem('user')) != null) {
        this.subscriptionsService.getUserSubscription(JSON.parse(sessionStorage.getItem('user')).uid).subscribe(subscrip => {
          if (subscrip != null) {
            this.subscriptionUser = subscrip;
            // console.log('===>' + JSON.stringify(this.subscriptionUser));

            // tslint:disable-next-line: max-line-length
            if (JSON.parse(sessionStorage.getItem('user')).uid == this.subscriptionUser[JSON.parse(sessionStorage.getItem('user')).uid].id) {
              this.subscriberPlanUser = true;
            } else {
              this.subscriberPlanUser = false;
            }
          }
        });
      }


      this.subscriptionsService.getSubscription().subscribe(subscrip => {
        this.subscripUser = [];
        if (subscrip != null) {
          this.allSubscripUser = subscrip;
          // this.subscripUser = [];
          for (let ind = 0; ind < subscrip.length; ind++) {
            this.subscripUser.push(subscrip[ind].id);
            // console.log('===>' + this.subscripUser[ind]);
          }
        }
      });
   }
  //@ViewChild(IonInfiniteScroll, {static: false}) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonInfiniteScroll, { static: false } as any) infiniteScroll: IonInfiniteScroll;

  user: any = JSON.parse(sessionStorage.getItem('user'));

  pilots: any;
  photo = 'assets/imgs/blank_pp.png';
  view = 'pilot';

  ownProfile: any;

  starting: any;

  userStarting: any;

  pilotSearch: any;

  serach = '';

  page = 0;

  count: any = 0;
  countCompanies: any = 0;
  filter: any = 0;

  companies: any = [];
  anotherCompanies: any = [];
  companySearch: any;

  searchWithLicense: any;
  searchWithFixedWingHours: any;
  searchWithHelicopterHours: any;
  itemHelicopterHoursDisabled: any;
  itemFixedWingDisabled: any;
  searchStudentPilot: any;

  license = [
    {name: 'ATPL(A)', value: 'atplA'},
    {name: 'CPL(A)', value: 'cplA'},
    {name: 'ATPL(H)', value: 'atplH'},
    {name: 'CPL(H)', value: 'cplH'},
  ];

  studentPilot = [
    {name: 'Student Pilot', value: 'true'}
  ];

  hours = [
    {name: '0', value: '0'},
    {name: '500', value: '500'},
    {name: '1000', value: '1000'},
    {name: '3000', value: '3000'},
  ];

   customAlertOptions: any = {
    header: 'License List',
    subHeader: 'Select license type',
    translucent: true
  };

  customAlertHourOptions: any = {
    header: 'Hours',
    subHeader: 'Select Hours For Fixed Wing',
    translucent: true
  };

  allSubscripUser: any = [];
  subscripUser: any = [];
  checkUserList: any = [];

  async ngOnInit() {
    const loading = await this.loadingController.create({
      message: this.translate.instant('LOADING.message')
    });
    loading.present();

    this.pilotService.getPilots()
        .subscribe(data => {
          this.pilots = data;
          loading.dismiss();
          this.pilotSearch = this.pilots;
          this.starting = this.pilots[this.pilots.length - 1].name;
        });
        this.pilotService.getAllPilot().subscribe(data => {
          this.count = 0;
          data.map(plt => {
            if ((plt.fixed_wing_hour + plt.helicopter_hour) > 0) {
               this.count++;
             }
          });
        });

        this.loginService.getAllUser().subscribe(data => {
          this.countCompanies = data.length;
        });

  }

  getSubscriptionData(userid) {
    if (this.subscripUser != null) {
      const data = this.subscripUser.indexOf(userid);
      if (data > -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  onGotoPilotDetails(pilot) {
    this.navCtrl.navigateForward(`/tabs/network/profile/${pilot.id})`);
  }
  onGotoCOmpayDetails(c) {
    this.navCtrl.navigateForward([`/company-details/${c.id}`]);
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('ALERT.warning'),
      message: this.translate.instant('ALERT.NETWORK.sorry_you_did_not'),
      buttons: ['OK']
    });

    await alert.present();
  }

  companyView() {
    this.view = 'company';

    this.loginService.getUsers().subscribe(companies => {
      this.companies = companies;
      this.companySearch = [];
      this.userStarting = this.companies[this.companies.length - 1].Name;
      this.companySearch = Array.from(new Set(this.companies));
    });
  }

  pilotView() {
    this.view = 'pilot';
  }

  onChangeTime() {
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).uid : null;
    if (user == null) {
      this.router.navigate(['/login']);
    }
  }

  filterBySearch() {
    const user = sessionStorage.getItem('user');
    if (user == null) {
      this.serach = '';
      this.router.navigate(['/login']);


    }

    const searchkey = this.serach;
    this.pilotSearch = this.pilots.filter(function(el) {
      if (el.name) {
        return el.name.toLowerCase().indexOf(searchkey.toLowerCase()) > -1;
      }
      });

      // TODO: notice casing on Name
    this.companySearch = this.companies.filter(function(er) {
      if (er.Name) {
        return er.Name.toLowerCase().indexOf(searchkey.toLowerCase()) > -1;
      }
     });

    this.count = 0;
    this.pilotSearch.map(item => {
      if ((item.fixed_wing_hour + item.helicopter_hour) > 0) {
       this.count++;
      }
    });

   this.countCompanies = 0;
   this.companySearch.map(item => {
    this.countCompanies++;
  });


  }
  filterSearch() {
    const firestore = firebase.firestore();
    const col = firestore.collection('pilots');
    let query: any;

    if (this.searchWithLicense && this.searchWithFixedWingHours) {
      query = col
      .where(this.searchWithLicense , '==', true)
// tslint:disable-next-line: radix
      .where('fixed_wing_hour' , '>=', parseInt(this.searchWithFixedWingHours));
    } else if (this.searchWithFixedWingHours) {
      // tslint:disable-next-line:radix
      query = col.where('fixed_wing_hour' , '>=', parseInt(this.searchWithFixedWingHours));
    } else if (this.searchWithHelicopterHours) {
      // tslint:disable-next-line:radix
      query = col.where('helicopter_hour' , '>=', parseInt(this.searchWithHelicopterHours));
    } else if (this.searchWithLicense) {
      query = col.where(this.searchWithLicense , '==', true);
    } else if (this.searchStudentPilot) {
      query = col.where('student_pilot' , '==', true);
    } else {
        query = col;
        this.pilotSearch = this.pilots;
        this.view = 'pilot';
        this.searchWithFixedWingHours = null;
        this.searchWithHelicopterHours = null;
        this.searchWithLicense = null;
        this.searchStudentPilot = null;

    }
    if (query) {
      query.get().then(snapshot => {
        this.view = 'pilot';
        // // debugger;
        // this.pilots = [];
        this.pilotSearch = [];
        this.filter = snapshot.docs.length;
        this.count = 0;
        snapshot.docs.forEach(doc => {
          if ((doc.data().fixed_wing_hour + doc.data().helicopter_hour) > 0) {
            if (this.searchStudentPilot) {
              if (doc.data().student_pilot) {
                this.pilotSearch.push(doc.data());
                this.checkUserList.push(doc.id);
                this.count++;
              }
            } else {
              this.pilotSearch.push(doc.data());
              this.checkUserList.push(doc.id);
              this.count++;
            }
          }
        });
      });
    }
    this.itemFixedWingDisabled = false;
    this.itemHelicopterHoursDisabled = false;

    /*this.subscriptionsService.getSubscription().subscribe(subscrip => {
      // debugger;
      if (subscrip != null) {
        this.allSubscripUser = subscrip;
        //this.subscripUser = [];
        for (let ind = 0; ind < subscrip.length; ind++)
        {
          this.subscripUser.push({'id':subscrip[ind].id});
          console.log("===>"+this.subscripUser.id);
        }
      }
    });*/
   }
   
   cancelFilters(){
    
    this.searchWithFixedWingHours = null;
      this.searchWithHelicopterHours = null;
      this.searchWithLicense = null;
      this.searchStudentPilot = null;
      this.filterSearch();
   }

   filters() {
    // this.subscriptionsService.getAllSubcription(this.subscriptionUser)

    if (!this.loggedInOrNot) {
      this.router.navigate(['/login']);
      return;
    }

    /* if (this.subscriberPlanUser) { */
      this.view = 'filters';
      this.searchWithFixedWingHours = null;
      this.searchWithHelicopterHours = null;
      this.searchWithLicense = null;
      this.searchStudentPilot = null;
    /* } else {
      this.presentPlanExpiryAlertPrompt('This feature is reserved for subscribers. Please <a href=\'/subscriptions\'>click here to upgrade</a>.');
    } */
  }


   changeLicense(event) {
      this.searchWithLicense = event.value;
   }

   changeStudent(event) {
    this.searchStudentPilot = event.value;
 }

   changeFixedWingHours(event) {
    this.itemHelicopterHoursDisabled = true;
    this.searchWithFixedWingHours = event.value;
   }
   changeHelicopterHours(event) {
    this.itemFixedWingDisabled = true;
    this.searchWithHelicopterHours = event.value;
   }


  async loadData(event) {
    const loading = await this.loadingController.create({
      message: this.translate.instant('LOADING.load_more_data')
    });
    // tslint:disable-next-line:no-unused-expression
    this.starting && loading.present();
    event.target.complete();
      // tslint:disable-next-line:no-unused-expression
      this.starting && this.pilotService.getWithLimit(this.starting).subscribe(data => {
        loading.dismiss();
        this.pilotSearch = [...this.pilotSearch, ...data];
        this.starting = this.pilotSearch[this.pilotSearch.length - 1] ? this.pilotSearch[this.pilotSearch.length - 1].name : null;
      });
  }

  async loadUserData(event) {
    const loading = await this.loadingController.create({
      message: 'Loading More Data'
    });

    // tslint:disable-next-line:no-unused-expression
    this.userStarting  && this.countCompanies > this.companySearch.length && loading.present();
    event.target.complete();
      // tslint:disable-next-line:no-unused-expression
      this.userStarting && this.countCompanies > this.companySearch.length &&
      this.loginService.getWithLimit(this.userStarting).subscribe(data => {
        loading.dismiss();
        // todo limit 50 hardcoded.
        // if(this.companySearch.length >= 50){
        // }
        this.companySearch = [...this.companySearch, ...data];
        this.companySearch = this.removeDuplicates(this.companySearch, 'id');
        this.companySearch = Array.from(new Set(this.companySearch));
        if (this.companySearch.length >= this.countCompanies) {
          this.userStarting = null;
        }
      });
  }

  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }

  async presentPlanExpiryAlertPrompt(msg) {
    const alert = await this.alertController.create({
      header: this.translate.instant('ALERT.JOB_DETAILS.subscription_required'),
      message: msg,
      buttons: [
        {
          text: this.translate.instant('ALERT.cancel_button'),
          role: 'cancel',
          cssClass: 'secondary',
        /*handler: () => {
          }*/
        }, {
          text: 'Ok',
          /*handler: (data) => {
          }*/
        }
      ]
    });
    await alert.present();
  }

  async openLanguagePopover(ev){
    const popover = await this.popoverCtrl.create({
      component: LanguagePopoverPage,
      event: ev
    });
    await popover.present();
  }
}
