import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, AlertController } from '@ionic/angular';
import { CountryService } from '../../../../services/country/country.service';
import { TypeRating } from '../../../../models/typerating.model';
import { PilotService } from '../../../../services/pilot/pilot.service';

@Component({
  selector: 'app-type-ratings-modal',
  templateUrl: './type-ratings-modal.page.html',
  styleUrls: ['./type-ratings-modal.page.scss'],
})
export class TypeRatingsModalPage implements OnInit {

  selected: any;

  typeRating: TypeRating = {
    fixed_wings: [],
    helicopter: []
  };

  fixed_wings: any;
  helicopters: any;


  ratingType: any;
  typeName: any;

  constructor(private countryService: CountryService,
    private alertCtrl: AlertController,
    private pilotService: PilotService,
    public navParams: NavParams,
    public modalCtrl: ModalController) {
    }

  ngOnInit() {
    const uid = JSON.parse(sessionStorage.getItem('user')).uid;
    this.pilotService.getTypeRating(uid).subscribe(typerating => {
      this.typeRating = typerating  ? typerating : this.typeRating;
    });

    this.ratingType = this.navParams.get('ratingType');
    this.typeName = this.navParams.get('typeName');

    //console.log("typeName==>"+JSON.stringify(this.typeName));
    this.fixed_wings = this.countryService.getFixedWings();
    this.helicopters = this.countryService.getHelicopters();
  }


  /*changeFixedWingsBySearch(event) {
    this.selected = this.countryService.getSelectedFixedWing(event.value.name)[0];

    const uid = JSON.parse(sessionStorage.getItem('user')).uid;
    if (this.selected) {
      this.typeRating.fixed_wings.push(this.selected);
    }
    this.pilotService.addTypeRating(this.typeRating, uid);
    this.selected = null;
    this.StartTimer();
  }*/
  changeFixedWingsBySearch(item){
    this.selected = this.countryService.getSelectedFixedWing(item.name)[0];

    const uid = JSON.parse(sessionStorage.getItem('user')).uid;
    if (this.selected) {
      this.typeRating.fixed_wings.push(this.selected);
    }
    this.pilotService.addTypeRating(this.typeRating, uid);
    this.selected = null;
    this.StartTimer();
  }

  /*changeHelicoptersBySearch(event){
    this.selected = this.countryService.getSelectedHelicopter(event.value.name)[0];

    const uid = JSON.parse(sessionStorage.getItem('user')).uid;
    if (this.selected) {
      this.typeRating.helicopter.push(this.selected);
    }
    this.pilotService.addTypeRating(this.typeRating, uid);
    this.selected = null;
    this.StartTimer();
  }*/
  changeHelicoptersBySearch(item){
    this.selected = this.countryService.getSelectedHelicopter(item.name)[0];

    const uid = JSON.parse(sessionStorage.getItem('user')).uid;
    if (this.selected) {
      this.typeRating.helicopter.push(this.selected);
    }
    this.pilotService.addTypeRating(this.typeRating, uid);
    this.selected = null;
    this.StartTimer();
  }

  async StartTimer() {
    setTimeout(() => {
      this.closeModal();
    }, 1000);
  }

  public closeModal() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  getFixedWingsItems(ev) {
    this.fixed_wings = this.countryService.getFixedWings();
    var val = ev.target.value;
    if (val && val.trim() != '') {
      this.fixed_wings = this.fixed_wings.filter((item) => {
        return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
    }
  }


  getHelicoptersItems(ev) {
    this.helicopters = this.countryService.getHelicopters();
    var val = ev.target.value;
    if (val && val.trim() != '') {
      this.helicopters = this.helicopters.filter((item) => {
        return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
    }
  }

  

  /*closeModal() {
    if (this.ratingType == 'fixed_wings') {
      this.modalCtrl.dismiss(this.typeRating.fixed_wings);
    } else if (this.ratingType == 'helicopter') {
      this.modalCtrl.dismiss(this.typeRating.helicopter);
    } else {
      this.modalCtrl.dismiss();
    }
  }*/

}
