<ion-app>
  <ion-split-pane contentId="main">
    <ion-menu contentId="main" side="end">
      <ion-header>
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-item [routerDirection]="'root'" href={{p.url}}>
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" >
              <div *ngIf="user;then logout else login">here is ignored</div>    
              <ng-template #logout>
                <ion-item (click)="logout3()">
                  <ion-icon slot="start" name="log-in"></ion-icon>
                  <ion-label>
                     {{'SIDEBARS.log_out' | translate}}
                  </ion-label>
                </ion-item>
              </ng-template>
              <ng-template #login>
                <ion-item routerLink="/login">
                  <ion-icon slot="start" name="unlock"></ion-icon>
                  <ion-label>
                    {{'SIDEBARS.login' | translate}}
                  </ion-label>
                </ion-item>
              </ng-template>

              <ion-item routerLink="/about">
                <ion-icon slot="start" name="apps"></ion-icon>
                <ion-label>
                    {{'SIDEBARS.about_the_app' | translate}}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
        </ion-list>

        <ion-card>
          <ion-card-header>
            <ion-card-title>{{'SIDEBARS.aviation_network' | translate}}</ion-card-title>
           </ion-card-header>
                
          <ion-card-content>
              <p class="cv-text">
                  {{'SIDEBARS.the_carrer' | translate}}

                  <!-- <br>
                  <ion-button size="small" color="primary"
                   onclick="window.open('http://airpilotbase.com', '_system');">Read 
                   more at airpilotbase.com</ion-button> -->
      
      
                </p>
          </ion-card-content>
        </ion-card>
        <div *ngIf="linkShow" class="ion-text-center">
          <p>{{'SIDEBARS.get_the_app' | translate}}</p>
          <a href="https://apps.apple.com/us/app/airpilotbase/id1453981375?mt=8" style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-05-19&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;"></a>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>

<!-- <ion-app>
  <ion-split-pane>
    <ion-menu>
        <ion-header>
            <ion-toolbar>
              <ion-title>Profile</ion-title>
            </ion-toolbar>
          </ion-header>
          
          <ion-content padding>
            <ion-list>
                
                <ion-item menuClose detail-none (click)="showprofile()">
                  <ion-icon name="contact" item-start></ion-icon> My Account  </ion-item>
              </ion-list>
                <ion-list>
                  <ion-item menuClose detail-none (click)="editPilotCv()">
                    <ion-icon name="create" item-start></ion-icon> Manage CV   </ion-item>
              </ion-list>
              <ion-list>
                <ion-item menuClose detail-none (click)="logout()">
                  <ion-icon name="log-out" item-start></ion-icon> Sign out   </ion-item>
            </ion-list>
          </ion-content>
          
    </ion-menu>
      <ion-router-outlet main></ion-router-outlet>
  </ion-split-pane>
</ion-app> -->
