import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { User } from '../../models/user.model';
import {Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';

export interface Todo {
  id?: string;
  email: string;
  city: string;
  country: string;
  name: string;
  description: string;
  logo: string;
  role: string;
  website: string;
  backgroundPhotoUrl: string;
}

interface QueryConfig {
  path: string; //  path to collection
  field: string; // field to orderBy
  limit: number; // limit per query
  reverse: boolean; // reverse order?
  prepend: boolean; // prepend to source?
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loggedIn: any;

  private query: QueryConfig = {
    field: 'Name',
    limit: 50,
    path: 'users',
    prepend: true,
    reverse: false
  };

  private todosCollection: AngularFirestoreCollection<Todo>;
  private userCollection: AngularFirestoreCollection<User>;


  private users: Observable<User[]>;

  constructor(private db: AngularFirestore) {
    this.userCollection = db.collection<User>('users');

    this.users = this.userCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

   }



   getUser(id) {
     return this.userCollection.doc<User>(id).valueChanges();
   }

   getUsers() {
    const first = this.db.collection(this.query.path, ref => {
      return ref
          .orderBy(this.query.field)
          .where('role', '==', '11')
          .limit(this.query.limit);
      });
      return first.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data  as {} };
          });
        })
    );
   }
   addTodo(todo: Todo, id) {
    // return this.todosCollection.add(todo);
    return this.todosCollection.doc(id).set(todo); // MAGIC LINE

  }
  addUser(user: User, id) {
    return this.userCollection.doc(id).set(user); // MAGIC LINE
  }
  deleteUser(id) {
    return this.userCollection.doc(id).delete();

  }
   updateTodo(todo: Todo, id: string) {
    return this.todosCollection.doc(id).update(todo);
   }
   updateUser(user: User, id: string) {
     console.log(user);
     console.log(id);
    return this.userCollection.doc(id).update(user);
   }


   getTodoByEmail(email) {
    const firestore = firebase.firestore();
    // firestore.settings({timestampsInSnapshots: true});

    const col = firestore.collection('todos');

    const query = col.where('email', '==', email);

    query.get().then(snapshot => {
      snapshot.docs.forEach(doc => {
      });
    });
   }

   getWithLimit( starting: any)  {
    const first = this.db.collection(this.query.path, ref => {
      return ref
              .orderBy(this.query.field)
              .startAfter(starting)
              .where('role', '==', '11')
              .limit(this.query.limit);
    });

    return first.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data  as {}};
        });
      })
    );
  }

  getAllUser() {
    const first = this.db.collection(this.query.path, ref => {
      return ref.where('role', '==', '11');
    });

    return first.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data  as {} };
        });
      })
    );
  }

  getTotalUser() {
    return this.userCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
}
