<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <span ion-text color="primary" showWhen="ios"></span>
        <ion-icon name="md-close" showWhen="android,windows"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{typeName}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>


  <div *ngIf="ratingType == 'fixed_wings'">
  <!-- <ion-item>
    <ion-label [color]="'primary'">Add Fixed Wing</ion-label>
    <ionic-selectable [items]="fixed_wings" itemValueField="name" itemTextField="name" [canSearch]="true"
      (onChange)="changeFixedWingsBySearch($event)" placeholder="Select">
    </ionic-selectable>
  </ion-item>  -->
  <ion-searchbar (ionInput)="getFixedWingsItems($event)"></ion-searchbar>
	<ion-list>
    <ion-radio-group allow-empty-selection = "true">
			<ion-item *ngFor="let item of fixed_wings">
        <!-- <ion-radio slot="start" value="biff" checked>{{ item }}</ion-radio> -->
        <ion-label>{{item.name}}</ion-label>
        <ion-radio value="{{item.name}}" (click)="changeFixedWingsBySearch(item)"></ion-radio>
			</ion-item>
    </ion-radio-group>
	</ion-list>
</div>


  <!-- <div *ngIf="ratingType == 'fixed_wings'">
    <ionic-selectable #fixedWingsList  [items]="fixed_wings" itemValueField="name" itemTextField="name" [canSearch]="true"
      (onChange)="changeFixedWingsBySearch($event)" placeholder="Select">
    </ionic-selectable>
  </div> -->

  <div *ngIf="ratingType == 'helicopter'">
  <!-- <ion-item>
    <ion-label [color]="'primary'">Add Helicopter</ion-label>
    <ionic-selectable [items]="helicopters" itemValueField="name" itemTextField="name" [canSearch]="true"
      (onChange)="changeHelicoptersBySearch($event)" placeholder="Select">
    </ionic-selectable>
  </ion-item> -->
  <ion-searchbar (ionInput)="getHelicoptersItems($event)"></ion-searchbar>
	<ion-list>
    <ion-radio-group allow-empty-selection = "true">
			<ion-item *ngFor="let item of helicopters">
        <!-- <ion-radio slot="start" value="biff" checked>{{ item }}</ion-radio> -->
        <ion-label>{{item.name}}</ion-label>
        <ion-radio value="{{item.name}}" (click)="changeHelicoptersBySearch(item)"></ion-radio>
			</ion-item>
    </ion-radio-group>
	</ion-list>
</div> 

  <!-- <div *ngIf="ratingType == 'helicopter'">
    <ionic-selectable #helicopterList [items]="helicopters" itemValueField="name" itemTextField="name" [canSearch]="true"
      (onChange)="changeHelicoptersBySearch($event)" placeholder="Select">
    </ionic-selectable>
  </div> -->

</ion-content>
