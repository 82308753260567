import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-language-popover',
  templateUrl: './language-popover.page.html',
  styleUrls: ['./language-popover.page.scss'],
})
export class LanguagePopoverPage implements OnInit {

  languages = [];
  selected = '';

  constructor(
    private popoverCtrl: PopoverController,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.languages = this.languageService.getLanguages();
    this.selected = this.languageService.selected;

    if (navigator.language.includes('en')) {
      console.log(navigator.language);
      this.select('en');
    } else {
      this.select('en');
    }
  }

  select(lng) {
    this.languageService.setLanugage(lng);
    this.popoverCtrl.dismiss();
  }
  
}
