import { environment } from '../environments/environment';
export const FIREBASE_CONFIG = environment.production ? {
    apiKey: "AIzaSyBOKn-7I5Gn4IfCcK4OOMuTQgQMxhngheU",
    authDomain: "apb-prod.firebaseapp.com",
    databaseURL: "https://apb-prod.firebaseio.com",
    projectId: "apb-prod",
    storageBucket: "apb-prod.appspot.com",
    messagingSenderId: "263957600921"
} : {
    apiKey: "AIzaSyAxJC1GR6jPvxv1rAWFkFaAxxdRVpGUaYA",
    authDomain: "apb-dev.firebaseapp.com",
    databaseURL: "https://apb-dev.firebaseio.com",
    projectId: "apb-dev",
    storageBucket: "apb-dev.appspot.com",
    messagingSenderId: "420865992720"
};